/* eslint-disable @typescript-eslint/naming-convention */
import { FuncOrGroup } from '@react-awesome-query-builder/antd';

const FIND = (customFields: any): FuncOrGroup => ({
  label: 'FINDCHAR',
  sqlFunc: 'CHARINDEX',
  returnType: 'CHARINDEX',
 
  args: {
    value: {
      label: 'Enter Value',
      type: 'text',
      valueSources: ['value'],
    },
    field: {
      label: 'Select Field',
      type: 'a8fields',
      valueSources: ['value'],
      allowCustomValues: true,
      listValues: Object.keys(customFields),
    },
  },
  allowSelfNesting: true,
  jsonLogic: (formattedArgs) => {
    debugger
    return { 
      FINDCHAR: [{ var: formattedArgs.field},  formattedArgs.value] 
    }
  },
  formatFunc: (formattedArgs) =>
    `CHARINDEX(${formattedArgs.value.replaceAll("'", '')}, ${formattedArgs.field.replaceAll("'", '')})`,
  sqlFormatFunc: (formattedArgs) =>
    `CHARINDEX(${formattedArgs.value.replaceAll("'", '')}, ${formattedArgs.field.replaceAll("'", '')})`,
});

export default FIND;
